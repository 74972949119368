import { useState } from "react";

import { useLocalStorage } from "@uidotdev/usehooks";

import { Button } from "shared/ui/button";
import { Icon } from "shared/ui/icon";

export const Welcome = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [_, setHideIntroduction] = useLocalStorage("hideIntroduction", false);

  const hideWelcomeHandler = () => {
    setHideIntroduction(true);
  };

  return (
    <div className="absolute z-20 h-screen w-[calc(100%-80px)] bg-white">
      <Icon
        className="absolute right-[44px] top-[45px] z-[100] size-[28px] cursor-pointer stroke-black xl:right-[55px] 2xl:right-[75px]"
        name="crossTiny"
        onClick={hideWelcomeHandler}
      />

      <SlideOne
        setCurrentSlide={() => setCurrentSlide(1)}
        show={currentSlide === 0}
      />

      <SlideTwo
        setCurrentSlide={() => setCurrentSlide(2)}
        show={currentSlide === 1}
      />

      <SlideThree
        setCurrentSlide={() => setCurrentSlide(3)}
        show={currentSlide === 2}
      />

      <SlideFour
        setCurrentSlide={() => setCurrentSlide(4)}
        show={currentSlide === 3}
      />

      <SlideFive
        setCurrentSlide={() => setCurrentSlide(5)}
        show={currentSlide === 4}
      />

      <SlideSix
        setCurrentSlide={() => setCurrentSlide(6)}
        show={currentSlide === 5}
      />

      <SlideSeven hideWelcome={hideWelcomeHandler} show={currentSlide === 6} />
    </div>
  );
};

type SlideProps = {
  hideWelcome?: () => void;
  setCurrentSlide?: () => void;
  show: boolean;
};

const SLIDE_IMG_CN =
  "hidden h-screen bg-cover bg-center bg-no-repeat lg:block lg:w-1/2";

const SLIDE_CONTENT_CN =
  "flex flex-col h-screen items-start justify-center space-y-[25px] px-[44px] lg:w-1/2 xl:px-[55px] 2xl:px-[75px]";

const SlideOne = ({ setCurrentSlide, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide1.jpg')` }}
      ></div>
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[48px] font-semibold">
          Welcome aboard,{" "}
          <span className="text-sunriseOrange">hitchhiker!</span> 🚀✨
        </h2>
        <p className="text-[16px] leading-[24px] text-riverBed">
          At Galactica, we invite you on a journey through the decentralized
          universe where your privacy is the ultimate answer to life, the
          universe, and everything. Sure, it’s a bit of a Vogon poetry recital
          under the hood, but we’ve made it as easy to understand and use.
        </p>
        <img src="/welcome/content1.png" />
        <p className="text-[16px] leading-[24px] text-riverBed">
          Glance at the expanse - give this guide 3 minutes of your time — you
          won’t regret it.
        </p>
        <Button className="h-[40px] w-[250px]" onClick={setCurrentSlide}>
          Start guide
        </Button>
      </div>
    </div>
  );
};

const SlideTwo = ({ setCurrentSlide, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[30px] font-semibold">
          Secure Your Personal Space with
          <br />
          <span className="text-sunriseOrange">MetaMask Snaps</span>
        </h2>
        <img src="/welcome/content2.png" />
        <p className="text-[16px] leading-[24px] text-riverBed">
          First, equip your intergalactic towel by installing the Galactica
          MetaMask Snaps Extension. This ensures all your personal data stays
          secure on your own device—no dark, data-hoarding hyperspace anomalies
          here. Just smooth and secure travels through the stars.
        </p>
        <p className="text-[16px] leading-[24px] text-riverBed">
          Fast, easy and secure. No intermediaries included.
        </p>
        <Button className="h-[40px] w-[250px]" onClick={setCurrentSlide}>
          Next <Icon className="ml-[8px] w-[16px]" name="arrowRight" />
        </Button>
      </div>
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide2.jpg')` }}
      ></div>
    </div>
  );
};

const SlideThree = ({ setCurrentSlide, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide3.jpg')` }}
      ></div>
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[30px] font-semibold">
          Discover <span className="text-sunriseOrange">zkKYC</span> Guardians
        </h2>
        <img src="/welcome/content3.png" />
        <p className="!mt-[15px] text-[16px] leading-[24px] text-riverBed">
          Next, hitch a ride to one of Galactica’s trusted zkKYC Guardians.
          Complete a quick mission (we promise it’s smoother than navigating the
          hyperspace bypass) and earn your encrypted zkKYC Certificate. Think of
          it as your personal guide entry—safe, encrypted, and never meant to be
          left behind on the floor of a spaceport bar.
        </p>
        <Button className="h-[40px] w-[250px]" onClick={setCurrentSlide}>
          Next <Icon className="ml-[8px] w-[16px]" name="arrowRight" />
        </Button>
      </div>
    </div>
  );
};

const SlideFour = ({ setCurrentSlide, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[30px] font-semibold">
          Prove Your Galactic Identity, Keep the Mysteries
        </h2>
        <img src="/welcome/content4.png" />
        <p className="text-[16px] leading-[24px] text-riverBed">
          Link your zkKYC to MetaMask to prove vital things like:
        </p>
        <div className="flex space-x-[10px] text-[14px] text-riverBed">
          <div className="w-1/3">
            <span className="text-[24px]">✅</span>
            <div>You’re a bona fide human, not an errant bowl of petunias.</div>
          </div>
          <div className="w-1/3 ">
            <span className="text-[24px]">🎂</span>
            <div>You’re old enough to sip a Pan Galactic Gargle Blaster.</div>
          </div>
          <div className="w-1/3 ">
            <span className="text-[24px]">🌐</span>
            <div>
              You’re a proud Citizen of earthly states, such as US, France or
              otherwise.
            </div>
          </div>
        </div>
        <p className="text-[16px] leading-[24px] text-riverBed">
          <span className="font-semibold">Here’s the twist:</span> you only
          reveal just enough to keep your adventure going. And even that remains
          encrypted. Zero Knowledge cryptography is truly a cosmic enigma.
        </p>
        <Button className="h-[40px] w-[250px]" onClick={setCurrentSlide}>
          Next <Icon className="ml-[8px] w-[16px]" name="arrowRight" />
        </Button>
      </div>
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide4.jpg')` }}
      ></div>
    </div>
  );
};

const SlideFive = ({ setCurrentSlide, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide5.jpg')` }}
      ></div>
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[30px] font-semibold">
          🚀 Build Your Stellar Reputation
        </h2>
        <img src="/welcome/content5.png" />
        <p className="text-[16px] leading-[24px] text-riverBed">
          Boost your galactic reputation by completing on-chain missions. Embark
          on Soul Matrix Quests, mint unique SBTs, and join interstellar
          campaigns.
        </p>
        <p className="text-[16px] leading-[24px] text-riverBed">
          After all, even Zaphod Beeblebrox himself wouldn’t pass up the chance
          to show off a dazzling list of interstellar accomplishments.
        </p>
        <Button className="h-[40px] w-[250px]" onClick={setCurrentSlide}>
          Next <Icon className="ml-[8px] w-[16px]" name="arrowRight" />
        </Button>
      </div>
    </div>
  );
};

const SlideSix = ({ setCurrentSlide, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[30px] font-semibold">Reap Universal Rewards</h2>
        <img src="/welcome/content6.png" />
        <p className="text-[16px] leading-[24px] text-riverBed">
          Link your zkKYC to MetaMask to prove vital things like:
        </p>

        <p className="text-[16px] leading-[24px] text-riverBed">
          The brighter your reputation shines, the more you qualify for
          Galactica’s UBI Pools. Yes, you can earn UBI (GNET) just for being an
          active traveler in the cosmos. No strings attached (except maybe a
          couple of improbability-induced twists, but nothing you can’t handle).
        </p>
        <Button className="h-[40px] w-[250px]" onClick={setCurrentSlide}>
          Next <Icon className="ml-[8px] w-[16px]" name="arrowRight" />
        </Button>
      </div>
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide6.jpg')` }}
      ></div>
    </div>
  );
};

const SlideSeven = ({ hideWelcome, show }: SlideProps) => {
  return (
    <div
      className={`absolute flex size-full bg-white ${show ? "z-20" : "z-10"}`}
    >
      <div
        className={SLIDE_IMG_CN}
        style={{ backgroundImage: `url('/welcome/slide7.jpg')` }}
      ></div>
      <div className={SLIDE_CONTENT_CN}>
        <h2 className="text-[30px] font-semibold">
          Enjoy the Wonders of{" "}
          <span className="text-basketBallOrange">Galactica.com</span>
        </h2>
        <p className="text-[16px] leading-[24px] text-riverBed">
          That’s it! You’re now officially a part of our celestial community. So
          set your course, don’t panic, and enjoy the infinite possibilities of
          being a Galactica explorer. Your privacy is secured among the stars,
          and the decentralized universe is your oyster.
        </p>
        <p className="text-[16px] font-semibold leading-[24px] text-riverBed">
          Safe travels, hitchhiker, and may your journey through Galactica be
          nothing short of improbably extraordinary!
        </p>
        <Button className="h-[40px] w-[250px]" onClick={hideWelcome}>
          Start Journey!
        </Button>
      </div>
    </div>
  );
};
